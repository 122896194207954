.distance-marker {
  display: flex;
  align-items: center;
  min-width: 40px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 6px;
  padding: 4px;
}

.distance-marker img {
  width: 18px;
  height: 18px;
}

.distance-marker span {
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 4px;
}
