.brokerage-image {
  width: 31px;
  height: 31px;
  border-radius: 100px;
  border: 2px solid rgba(174, 161, 213, 0.5);
}

.brokerage-image.no-brand {
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: 3px 2px;
  background-color: #614bab;
}

.brokerage-image.other-brokerage {
  border-color: rgba(220, 52, 149, 0.5);
}

.mapboxgl-popup {
  text-align: center;
  max-width: 200px;
}

#home-pin-marker {
  background-size: contain;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
}
