/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bcbdc2d0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(146, 118, 206);
}

/* fix input autofill ugly styles */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
